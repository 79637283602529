<template>
	<el-container class="friend-page">
		<el-aside width="300px" class="friend-list-box">
			<div class="friend-list-header">
				<div class="friend-list-search">
					<el-input prefix-icon="el-icon-search" :placeholder="$t('comm.search')" v-model="searchText"></el-input>
				</div>
				<!-- <div class="addfriend" @click="addfriendsearch">
					<span class="iconfont icon-tianjiahaoyou"></span>
				</div> -->
			</div>
			<div>
				<slot></slot>
			</div>
			<el-scrollbar class="friend-list-items">
				<div class="top-title" @click="group_open=!group_open">
					<svg  class="icon" :class="{'icon-open':group_open}" aria-hidden="true"><use xlink:href="#icon-xiangyou1"></use></svg>
					<span>{{$t('comm.groupchat')}}</span>
					<span class="num-info">{{groupslength}}</span>
				</div>
				<div class="group-list" :style="{'height':group_height+'px'}">
					<div ref="groudlistref">
						<div v-for="(group,index) in groupStore.groups" :key="'g'+index">
							<group-item v-if="group.groupType!=2" v-show="!searchText.trim()||(group.remark||group.name).includes(searchText.trim())"  :index="index"
								:active="group.id == activate.id&&activate.type=='GROUP'" :group="group"
								@click.native="onActiveGroupItem(group,index)">
							</group-item>
						</div>
					</div>
				</div>
				<div class="top-title" @click="friend_open=!friend_open">
					<svg class="icon" :class="{'icon-open':friend_open}" aria-hidden="true"><use xlink:href="#icon-xiangyou1"></use></svg>
					<span>{{$t('comm.friend')}}</span>
					<span class="num-info">{{friendStore.friends.filter(fd=>fd.online).length}}/{{friendStore.friends.length}}</span>
				</div>
				<div class="friend-list" :style="{'height':friend_height+'px'}">
					<div ref="friendlistref">
						<div v-for="(friend,index) in friendStore.friends" :key="'f'+index">
							<friend-item v-show="!searchText.trim()||(friend.friendRemark||friend.nickName).includes(searchText.trim())" :imgsize="44" :index="index"
								:active="friend.id == activate.id&&activate.type=='PRIVATE'"
								@click.native="onActiveItem(friend,index)">
							</friend-item>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</el-aside>
		<!-- 用户信息 -->
		<el-container v-if="activate.type!='GROUP'||issearch" class="friend-box">
			<div style="width: 665px;margin: 52px auto">
				<div v-if="issearch" class="searchfriend">
					<el-input prefix-icon="el-icon-search" :placeholder="this.$t('comm.inputid')" v-model="searchid"  @keyup.enter.native="findfriend()" @blur="findfriend()">
					</el-input>
				</div>
				<template v-if="userInfo.id">
					<div class="friend-detail">
						<head-image  :size="80"
							:name="userInfo.nickName"
							:url="userInfo.headImage"
						></head-image>
						<div>
							<div class="info-item">
								<div style="margin-top: 3px">
									<span style="font-weight: 400;font-size: 20px;color: #333333;">{{ userInfo.friendRemark||userInfo.nickName}}</span>
									<span class="iconfont icon-xingbienan" style="color:#1485EE;font-size:20px;margin-left: 2px;vertical-align: text-bottom" v-show="userInfo.sex==0"/>
									<span class="iconfont icon-xingbienv" style="color:#F74F9E;font-size:20px;margin-left: 2px;vertical-align: text-bottom" v-show="userInfo.sex==1"/>
								</div>
								<div style="margin-top: 4px;text-align: left">
									<span style="font-weight: 400;font-size: 14px;color: #999999;">{{$t('comm.nickname')}}：{{ userInfo.nickName }}</span>
								</div>
								<div style="margin-top: 4px;text-align: left">
									<i style="display: inline-block;height:12px;margin-right: 8px;vertical-align: middle;width:12px;border-radius:50%;" :style="{'background-color':userInfo.online?'#67C23A':'#999999'}"></i>
									<span style="font-size: 14px;color:#333333;">{{userInfo.online?$t('comm.online'):$t('comm.Offline')}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="br-line" style="margin-top: 44px"></div>
					<div v-if="isFriend" style="width: 665px;height: 72px;display: flex">
						<div style="width: 146px;text-align: left"><span style="font-weight: 400;font-size: 18px;color: #999999;line-height: 72px;">{{this.$t('comm.Remark')}}</span></div>
						<div style="display:flex;flex:1;width:0;align-items: center;font-size: 18px;color: #333333;">
							<edit-string  :value="userInfo.friendRemark" :min="0" :placeholder="userInfo.friendRemark||userInfo.nickName" :max="100" filed="remark" @editafter="editfriendRemark"/>
						</div>
					</div>
					<div v-if="isFriend" class="br-line"></div>
					<div style="width: 665px;height: 88px;display: flex">
					<div style="width: 146px;text-align: left"><span style="font-weight: 400;font-size: 18px;color: #999999;line-height: 88px;">{{this.$t('comm.Pertags')}}</span></div>
					<div style="display: flex;">
						<div><span  style="color: #333333;font-weight: 400;font-size: 18px;line-height: 88px;">{{ userInfo.signature||this.$t('comm.signtis') }}</span></div>
						<div></div>
					</div>
					</div>
					<div class="br-line"></div>
					<div class="frient-btn-group">
						<el-button v-show="isFriend" style="width: 128px;height: 32px;background: #7678ED;border-radius: 4px;" @click="onSendMessage()"><span style="font-weight: 400;font-size: 14px;color: #FFFFFF;">{{$t('comm.sendmessage')}}</span></el-button>
						<el-button v-show="isFriend" style="width: 128px;height: 32px;background: #FFFFFF;border-radius: 4px;border: 1px solid #FF4D4F;" @click="onDelItem(userInfo)" plain><span style="font-weight: 400;font-size: 14px;color: #FF4D4F;">{{$t('comm.delfriends')}}</span></el-button>
						<el-button v-show="!isFriend" style="width: 128px;height: 32px;background: #7678ED;border-radius: 4px;" @click="onAddFriend(userInfo)"><span style="font-weight: 400;font-size: 14px;color: #FFFFFF;">{{$t('comm.addfriend')}}</span></el-button>
					</div>
				</template>
			</div>
		</el-container>
		<!-- 群聊信息 -->
		<el-container v-if="activate.type=='GROUP'" class="group-box">
			<div v-show="groupinfo.id" style="width: 665px;margin: 52px auto">
				<div class="friend-detail">
					<head-image  :size="80"
						:name="groupinfo.name"
						:url="groupinfo.headImage"
					></head-image>
					<div class="info-item">
						<div style="margin-top: 15px">
							<span style="font-weight: 400;font-size: 20px;color: #333333;">{{groupinfo.name}}</span>
						</div>
						<div style="margin-top: 10px;text-align: left">
							<span style="font-weight: 400;font-size: 14px;color: #999999;">{{$t('comm.GroupLeader')}}：{{ groupinfo.ownerName }}</span>
						</div>
					</div>
				</div>
				<div class="br-line" style="margin-top: 44px"></div>
				<div style="width: 665px;height: 72px;display: flex">
				<div style="width: 150px;text-align: left"><span style="font-weight: 400;font-size: 18px;color: #999999;line-height: 72px;">{{$t('comm.GroupRemark')}}</span></div>
					<div style="display:flex;flex:1;width:0;align-items: center;font-size: 18px;color: #333333;">
						<edit-string  :value="groupinfo.remark" :min="0" :placeholder="groupinfo.remark||groupinfo.name" :max="100" filed="remark" @editafter="editGroup"/>
					</div>
				</div>
				<!-- <div class="br-line"></div>
				<div style="width: 665px;height: 72px;display: flex">
				<div style="width: 146px;text-align: left"><span style="font-weight: 400;font-size: 18px;color: #999999;line-height: 72px;">{{$t('comm.myGroupailas')}}</span></div>
					<div style="display:flex;align-items: center;font-size: 18px;color: #333333;">
						<edit-string  :value="groupinfo.aliasName" :min="0" :placeholder="myname" :max="15" filed="aliasName" @editafter="editGroup"/>
					</div>
				</div> -->
				<div class="br-line"></div>
				<div style="width: 665px;min-height: 88px;display: flex;align-items: center;">
					<div style="width: 150px;text-align: left"><span style="font-size: 18px;color: #999999;line-height: 88px;">{{$t('comm.group_info')}}</span></div>
					<div style="flex:1;width: 0;margin: 10px 0px;font-size: 18px;color: #333333;line-height: 25px;">
						<div v-if="!isOwner"  style="text-align: left;"><span>{{ groupinfo.notice }}</span></div>
						<edit-string v-else :iswrap="false" :value="groupinfo.notice" :placeholder="$t('comm.notice_inputinfo')" :max="200" filed="notice" @editafter="editGroup"/>
					</div>
				</div>
				<div class="br-line"></div>
				<div class="frient-btn-group">
					<el-button  style="width: 128px;height: 32px;background: #7678ED;border-radius: 4px;" @click="onSendMessage()"><span style="font-weight: 400;font-size: 14px;color: #FFFFFF;">{{$t('comm.sendmessage')}}</span></el-button>
					<el-button v-if="!isOwner"  style="width: 128px;height: 32px;background: #FFFFFF;border-radius: 4px;border: 1px solid #FF4D4F;" @click="onQuit()" plain><span style="font-weight: 400;font-size: 14px;color: #FF4D4F;">{{$t('comm.exitgrouup')}}</span></el-button>
				</div>
			</div>
		</el-container>
	</el-container>
</template>

<script>
	import FriendItem from "@/components/friend/FriendItem.vue";
	import groupItem from "@/components/group/GroupItem.vue";
	import HeadImage from "@/components/common/HeadImage.vue";
	import EditString from "@/components/common/EditString.vue";

	export default {
		name: "friend",
		components: {
			FriendItem,
			groupItem,
			HeadImage,
			EditString,
		},
		data() {
			return {
				friend_open:false,
				group_open:false,
				group_height:0,
				friend_height:0,
				searchText: "",
				activate:{},
				userInfo: {},
				groupinfo:{},
				issearch:false,
				activeNames: ['2'],
				searchid:""
			}
		},
		watch:{
			friend_open: {
				handler() {
					this.set_friend_height()
				},
				immediate: true
			},
			'friendStore.friends.length':function(){
				this.set_friend_height()
			},
			'groupStore.groups.length':function(){
				this.set_group_height()
			},
			group_open: {
				handler() {
					this.set_group_height()
				},
				immediate: true
			},
			searchText:function(){
				this.group_height = 0
				this.friend_height = 0
				this.set_friend_height()
				this.set_group_height()
			}
		},
		methods: {
			set_group_height(){
				this.$nextTick(()=>{
					this.group_height =this.group_open? this.$refs.groudlistref.clientHeight:0
				})
			},
			set_friend_height(){
				this.$nextTick(()=>{
					this.friend_height =this.friend_open? this.$refs.friendlistref.clientHeight:0
				})
			},
			onActiveItem(friend, idx) {
				this.issearch = false
				this.activate ={id:friend.id,type:"PRIVATE"}
				this.userInfo = {};
				this.loadUserInfo(friend, idx);
			},
			onActiveGroupItem(group){
				this.issearch = false
				this.groupinfo = {};
				this.activate ={id:group.id,type:"GROUP"}
				this.loadGroupInfo(group);
			},
			addfriendsearch(){
				if(!this.issearch){
					this.activate = {}
					this.userInfo = {}
					this.searchid =""
					this.issearch = true
				}
			},
			onDelItem(friend) {
				this.$confirm(this.$t('comm.confirmDelmsg',[friend.nickName]), this.$t('comm.confirmDeltitle'), {
					confirmButtonText: this.$t('comm.confirm'),
					cancelButtonText:  this.$t('comm.cancel'),
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `/im/imFriend/delete/${friend.id}`,
						method: 'delete'
					}).then((data) => {
						this.$message.success(this.$t('comm.delsuccess'));
						let idx = this.$store.state.friendStore.friends.findIndex((f) => f.id === friend.id);
						this.$store.commit("removeFriend", idx);
						this.$store.commit("removePrivateChat", friend.id);
					})
				})
			},
			onAddFriend(user){
				this.$http({
					url: "im/imFriend/add",
					method: "post",
					params: {
						friendId: user.id
					}
				}).then((data) => {
					this.$message.success(this.$t('comm.AddSuccess'));
					// let friend = {
					// 	id:user.id,
					// 	nickName: user.nickName,
					// 	headImage: user.headImage,
					// 	online: user.online
					// }
					this.$store.commit("addFriend",user);
				})
			},
			onSendMessage() {
				let chat = null
				if(this.activate.type!='GROUP'||this.issearch){
					let user = this.userInfo
					chat = {
						type: 'PRIVATE',
						targetId: user.id,
						showName: user.friendRemark||user.nickName,
						headImage: user.headImage,
						disturbing: user.disturbing,
					};
				}else{
					let group = this.groupinfo
					chat = {
						type: 'GROUP',
						targetId: group.id,
						showName: group.remark||group.name,
						headImage:group.headImage,
						groupType: group.groupType,
						disturbing: group.disturbing,
					};
				}
				this.$store.commit("openChat", chat);
				this.$store.commit("activeChat", 0);
				this.$router.push("/home/chat");
			},
			loadUserInfo(friend) {
				this.$http({
					url: `/im/imUser/find/${friend.id}`,
					method: 'get'
				}).then((user) => {
					this.userInfo = user;
					// 如果发现好友的头像和昵称改了，进行更新
					if (user.headImage != friend.headImage||user.nickName != friend.nickName) {
						let friend =JSON.parse(JSON.stringify(user)) 
						this.$store.commit("updateChatFromFriend", friend);
						this.$store.commit("updateFriend", friend);
					}
				})
			},
			loadGroupInfo(group) {
				this.$http({
					url: `/im/imGroup/find/${group.id}`,
					method: 'get'
				}).then((group) => {
					this.groupinfo = group;
					this.$store.commit("updateChatFromGroup", group);
					this.$store.commit("updateGroup", group);
				})
			},
			editfriendRemark({value}) {
				value = value.trim()
				this.$http({
					url: "/im/imFriend/updateFriendById",
					method:'PUT',
					data:{
						friendId:this.userInfo.id,
						friendRemark:value
					}
				}).then((res) => {
					this.userInfo.friendRemark = value
					this.$store.commit("updateFriendRemark",{id:this.userInfo.id,remark:value});
					this.$store.commit("updateChatFromFriend",this.userInfo);
				})
			},
			editGroup({filed,value}){
				if(filed=="aliasName"&&!value.trim()){
					value = this.myname
				}
				let vo = JSON.parse(JSON.stringify(this.groupinfo));
				vo[filed] = value
				this.onSaveGroup(vo)
			},
			onSaveGroup(vo) {
				this.$http({
					url: "/im/imGroup/modify",
					method: "put",
					data: vo
				}).then((group) => {
					this.groupinfo = group;
					this.$store.commit("updateChatFromGroup", group);
					this.$store.commit("updateGroup", group);
					this.$message.success("修改成功");
				})
			},
			onQuit() {
				this.$confirm(this.$t('comm.exitgroupmsg'),this.$t('comm.exitgroupmsg'), {
					confirmButtonText: this.$t('comm.confirm'),
					cancelButtonText: this.$t('comm.cancel'),
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `im/imGroup/quit/${this.groupinfo.id}`,
						method: 'delete'
					}).then(() => {
						this.$store.commit("removeGroup", this.groupinfo.id);
						this.$store.commit("activeGroup", -1);
						this.$store.commit("activeChat", -1);
						this.$store.commit("removeGroupChat", this.groupinfo.id);
					});
				})
			},
			findfriend(){
				this.searchid = this.searchid.trim()
				if(this.searchid.trim()==this.userInfo.id){
					return;
				}
				this.userInfo ={}
				if(!this.searchid){
					this.$message.error(this.$t('comm.inputid'));
					return;
				}
				this.$http({
					url: `/im/imUser/find/${this.searchid}`,
					method: 'get'
				}).then((user) => {
					this.userInfo = user;
				})
			}
		},
		computed: {
			myname(){
				return this.$store.state.userStore.userInfo.nickName
			},
			friendStore() {
				return this.$store.state.friendStore;
			},
			groupStore() {
				return this.$store.state.groupStore;
			},
			groupslength(){
				let length=0;
				this.groupStore.groups.forEach(gs => {
					if(gs.groupType!=2){
						length++
					}
				});
				return length;
			},
			isFriend(){
				return this.friendStore.friends.find((f)=>f.id==this.userInfo.id);
			},
			isOwner() {
				return this.groupinfo.ownerId == this.$store.state.userStore.userInfo.id;
			}
		}
	}
</script>

<style scoped lang="scss">
	.friend-page {
		background-color: #ffffff;
		.edit-string{
			::v-deep{
				div .iswrap,div .nowrap,input,textarea{
					font-size: 18px;
					color: #333333;
					font-family:inherit;
				}
			}
		}
		.friend-list-box {
			display: flex;
			flex-direction: column;
			background: white;
			overflow: hidden;
			padding-right: 40px;
			padding-left: 16px;
			.friend-list-header {
				padding: 20px 0px 16px 0px !important;
				display: flex;
				align-items: center;
				padding: 5px;
				background-color: white;
				.friend-list-search {
					flex: 1;
					display: flex;
					::v-deep{
						.el-input__icon{
							line-height: 40px;
						}
						input{
							font-size: 16px;
							color: #626569;
							background-color: #DBDCFF;
							border-radius: 12px;
							border: none;
							height: 40px;
							&::placeholder{
								color: #626569;
							}
						}
						.el-input__icon{
							color: #626569;
						}
					} 
				}
				.addfriend{
					cursor: pointer;
					margin:0 10px;
					width: 40px;
					height: 40px;
					background: #DBDCFF;
					border-radius: 6px;
					box-sizing: border-box;
					&:hover{
						background: #c9cbff;
					}
					span{
						display: block;
						margin: 10px;
						color: #595959 ;
						font-size: 20px;
					}
				}
			}
			.friend-list-items{
				margin-bottom: 20px;
				.top-title{
					cursor: pointer;
					height: 40px;
					position: sticky;
					top:0;
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #6C757D;
					line-height: 20px;
					background-color: #FFFFFF;
					z-index: 1;
					border-radius: 8px;
					.icon{
						transition: all 0.4s;
						margin: 8px;
						height: 16px;
						width: 16px;
						&.icon-open{
							transform: rotate(90deg);
						}
					}
					.num-info{
						margin-right: 8px;
						margin-left: auto ;

					}
					&:hover{
						background: #F8F8FD;
					}
				}
				.group-list,.friend-list{
					transition: height 0.4s;
					display: flex;
					flex-direction: column;
					overflow: hidden;
				}
				::v-deep{
					.el-scrollbar__wrap{
						margin:0 !important;
					}
					.is-vertical{
						display: none;
					}
				}
			}
		}

		.friend-box,.group-box {
			.searchfriend{
				margin-bottom: 30px;
			}
			.br-line{
				width: 665px;
				height: 2px;
				background-color: #EEEEEE;
			}
			flex-direction: column;
			.friend-detail {
				display: flex;
				text-align: center;

				.info-item {
					text-align: left;
					margin-left: 24px;
					background-color: #ffffff;
				}

				.description {
					padding: 20px 20px 0px 20px;
				}
			}

			.frient-btn-group {
				margin-top: 40px;
			}
		}
	}
</style>
